import React, { useState, useEffect } from 'react';
import './Nav.css';
import { Link } from 'react-router-dom';

interface NavProps {
  selectedKey: string
}

const Nav: React.FC<NavProps> = ({selectedKey}) => {

  const [showMenu, setShowMenu] = useState(true);

  useEffect(() => {
    if (window.innerWidth < 480) {
      setShowMenu(false);
    }
  }, []);

  return (
    <nav>
      <img className="nav-logo" src="/assets/header_logo.png"></img>
      <div id="menu" onClick={() => setShowMenu(!showMenu)}>
      </div>
      {
        showMenu ? (
          <ul className="nav-ul">
            <li className={`nav-li${selectedKey === '1' ? ' selected' : ''}`}>
              <Link to="/">Inicio</Link>
            </li>
            <li className={`nav-li${selectedKey === '2' ? ' selected' : ''}`}>
              <Link to="/acercade">Acerca de</Link>
            </li>
            <li className={`nav-li${selectedKey === '3' ? ' selected' : ''}`}>
              <Link to="/contribuir">Contribuir</Link>
            </li>
          </ul>
        ) : ''
      }
      
    </nav>
  );
};

export default Nav;
