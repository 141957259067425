import './Contribute.css';
import React, { useState } from 'react';
import Nav from '../components/Nav';
import { saveContribute } from '../Firebase';

const Contribute: React.FC = () => {

  const [enviado, setEnviado] = useState(false);
  const [error, setError] = useState(false);

  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [tipo, setTipo] = useState('');
  const [comentarios, setComentarios] = useState('');

  const enviar = () => {
    if (nombre.trim() && email.trim() && telefono.trim() && tipo.trim() && comentarios.trim()) {
      saveContribute(nombre, email, telefono, tipo, comentarios);
      setNombre('');
      setEmail('');
      setTelefono('');
      setTipo('Tipo');
      setComentarios('');
      setEnviado(true);
    } else {
      setError(true);
    }
  }

  return (
    <div>
      <Nav selectedKey="3"></Nav>
      <div id="contribuir-container">
        <h1>¿DESEA COLABORAR CON MATERIAL HISTÓRICO SOBRE LA JUNTA DE PROTECCIÓN SOCIAL?</h1>
        <p>Si cuenta con fotografías, materiales impresos, videos u otros documentos valiosos para entender la historia de la Junta de Protección Social, por favor contáctenos:</p>
        <input name="nombre" id="nombre" type="text" placeholder="Nombre" value={nombre} onChange={(e: any) => { setNombre(e.target.value); setEnviado(false); setError(false);}}></input>
        <input name="email" id="email" type="email" placeholder="Email" value={email} onChange={(e: any) => { setEmail(e.target.value); setEnviado(false); setError(false);}}></input>
        <input name="telefono" id="telefono" type="text" placeholder="Teléfono" value={telefono} onChange={(e: any) => { setTelefono(e.target.value); setEnviado(false); setError(false);}}></input>
        <select name="tipo" id="tipo" defaultValue="Tipo" value={tipo} onChange={(e: any) => { setTipo(e.target.value); setEnviado(false); setError(false);}}>
          <option value="Tipo" disabled>Tipo de Material</option>
          <option value="Video">Video</option>
          <option value="Fotografía">Fotografía</option>
          <option value="Documento">Documento</option>
          <option value="Otro">Otro</option>
        </select>
        <textarea id="comentarios" name="comentarios" value={comentarios} rows={4} placeholder="Comentarios" onChange={(e: any) => { setComentarios(e.target.value); setEnviado(false); setError(false);}}>
        </textarea>
        { enviado ? <p className="center"><b>Gracias por su contribución</b></p> : '' }
        { error ? <p className="center"><b>Por favor ingrese todos los datos</b></p> : '' }
        <button onClick={() => {
            enviar();
        }}>Enviar</button>
      </div>
    </div>
  );
};

export default Contribute;
