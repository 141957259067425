import React, { FC } from 'react';
import Home from './pages/Home';
import About from './pages/About';
import Contribute from './pages/Contribute';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom";

const App: FC = () => (

  <Router>
      <Route path="/inicio" component={Home} exact={true} />
      <Route path="/acercade" component={About} exact={true} />
      <Route path="/contribuir" component={Contribute} exact={true} />
      <Route exact path="/" render={() => <Redirect to="/inicio" />} />
  </Router>
);

export default App;