import './About.css';
import React from 'react';
import Nav from '../components/Nav';

const About: React.FC = () => {

  return (
    <div>
      <Nav selectedKey="2"></Nav>
      <div id="acercade-container">
        <h1>"Solo la instrucción es el baluarte indestructible de la libertad de los pueblos, el firme apoyo de su tranquilidad, el paladín de sus derechos y la primordial causa de su engrandecimiento y prosperidad”</h1>
        <h2>José María Castro Madriz - Primer Presidente de Costa Rica</h2>
        <div className="acercade-text">
          <p>En conmemoración de su 175 aniversario, la Junta de Protección Social ha creado este repositorio de los hitos más importantes de su historia.</p>
          <p>La Junta ha sido vanguardia en la protección social del pueblo costarricense, estando siempre del lado de las poblaciones vulnerables, las causas justas y el bienestar de toda la población.</p>
          <p>Conocer la historia de la Junta es conocer la historia de un país que desde su fundación ha buscado la justicia social para todos sus ciudadanos. La evolución de esta institución acompaña el avance de nuestra democracia; su solidez representa el respeto profundo que este pueblo tiene por sus instituciones.</p>
          <p>Esta exposición es un tributo a la memoria del pueblo de Costa Rica que ha avanzado siempre sin dejar atrás a ningún hermano sin importar su condición.</p>
        </div>
        <hr></hr>
        <h2>Este es un esfuerzo institucional en que han participado:</h2>
        <div className="acercade-text">
          <p className="center">Brando Zúñiga Alvarado - Presidente</p>
          <p className="center">Brando Zúñiga Alvarado - Presidente</p>
          <p className="center">Brando Zúñiga Alvarado - Presidente</p>
          <p className="center">Brando Zúñiga Alvarado - Presidente</p>
          <p className="center">Brando Zúñiga Alvarado - Presidente</p>
          <p className="center">Brando Zúñiga Alvarado - Presidente</p>
        </div>
      </div>
    </div>
  );
};

export default About;
