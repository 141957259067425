import '../timelinejs/css/timeline.css';
import './Home.css';
import React, { useEffect } from 'react';
import Nav from '../components/Nav';
import { getData } from '../Firebase';
import { time } from 'console';

const Home: React.FC = () => {

  let data : any;

  const timelineColors = ['#F5AA2D', '#E65828', '#EC1C24', '#6D6E71', '#A7A9AC'];

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'timelinejs/js/timeline-min.js';
    script.async = true;
    document.body.appendChild(script);

    getData().then((firebaseData) => {
      let timelineData = firebaseData.map(hd => hd.data());
      timelineData = timelineData.sort((a,b) => a.createdate.seconds - b.createdate.seconds);
      console.log(timelineData);
      const tdata = timelineData.map((h) => {
        const hito = h;
          return {
          "media": {
            "url": hito.media.src + "#" + ((hito.media.title) ? hito.media.title.substr(hito.media.title.lastIndexOf('.') + 1) : ''),
          },
          "start_date": {
            "year": hito.start_date.year
          },
          "text": {
            "headline": hito.text.headline,
            "text": hito.text.text
          },
          "background": {
            "url": hito.background.src + '#.jpg',
          },
          "group": hito.group
        }
      });
      data = { events: tdata };
      console.log(data);
    });
    
    return () => {
        document.body.removeChild(script);
      }
  }, []);

  const initTimeLine = async () => {

    const element = document.getElementById('timeline-embed');
    if (window.innerWidth < 650) {
      (element as HTMLElement).style.cssText = 'min-height: 160vh; margin-top: 80px;';
    } else {
      (element as HTMLElement).style.cssText = 'height: 100%; min-height: 700px;';
    }

    setTimeout(() => {

      if (window.TL) {
        const timeline = new window.TL.Timeline('timeline-embed', data, { language: 'es' });

        setTimeout(async () => {
          const messages = document.getElementsByClassName('tl-message-content');
          const swipeMessage = Array.from(messages).filter(m => m.innerHTML.includes('Swipe to Navigate'));
          if (swipeMessage.length) {
            swipeMessage[0].innerHTML = 'Desliza para navegar<br><span class="tl-button">OK</span>';
          }
        }, 100);
        
        setYearStyle((data as any).events[0].group);
        timeline.on('change', (evData: any) => {
            const event = timeline.getDataById(evData.unique_id);
            setTimeout(async () => {
              setYearStyle(event.group);
            }, 500)
        });

        timeline.on('zoom_in', () => {
          console.log('axis');
          setAxisStyle();
        });

        timeline.on('zoom_out', () => {
          console.log('axis');
          setAxisStyle();
        });

        setAxisStyle();

      } else {
        initTimeLine();
      }
    }, 100);
  }

  const animateTitle = () => {
    try {
      const title = document.getElementById('inicio-cover');

      title!.animate([
          {opacity: 1},
          {opacity: 0}
      ], {
          duration: 1000,
          easing: 'ease-in',
          fill: 'forwards'
      });

      const timeline = document.getElementById('timeline-embed');

      timeline!.animate([
          {opacity: 0},
          {opacity: 1}
      ], {
          duration: 1000,
          easing: 'ease-in',
          fill: 'forwards'
      });


      setTimeout(() => {
        document.getElementById("inicio-cover")!.style.zIndex = '-1';
        initTimeLine();
      }, 1000);
    } catch {
      document.getElementById("inicio-cover")!.style.zIndex = '-1';
      document.getElementById("inicio-cover")!.style.opacity = '0';
      document.getElementById('timeline-embed')!.style.opacity = '1';
      initTimeLine();
    }
  }

  const setAxisStyle = () => {
    setTimeout(async () => {
      const timeGroups = document.getElementsByClassName('tl-timegroup');
      for (let index = 0; index < timeGroups.length; index++) {
        const element = timeGroups[index];
        (element as HTMLElement).style.setProperty('background-color', timelineColors[index%timelineColors.length]);
      }
    }, 100);
  }

  const setYearStyle = (group: string) => {
    const dates = document.getElementsByClassName('tl-headline-date');
    for (let index = 0; index < dates.length; index++) {
      const element = dates[index];
      switch (group) {
        case "Infraestructura Hospitalaria":
          (element as HTMLElement).style.cssText = 'color: ' + timelineColors[0] + ' !important; ';
          break;
        case "Emergencias Sanitarias":
          (element as HTMLElement).style.cssText = 'color: ' + timelineColors[1] + ' !important; ';
          break;
        case "Memoria Institucional":
          (element as HTMLElement).style.cssText = 'color: ' + timelineColors[2] + ' !important; ';
          break;
        case "Bienestar Social":
          (element as HTMLElement).style.cssText = 'color: ' + timelineColors[3] + ' !important; ';
          break;
        case "Sorteos":
          (element as HTMLElement).style.cssText = 'color: ' + timelineColors[4] + ' !important; ';
          break;
      }
    }
  }


  return (
    <div>
      <Nav selectedKey="1"></Nav>
      <div id="timeline-embed"></div>
      <div id="inicio-cover">
        <div id="inicio-l"><img src="/assets/logo.png"></img>
          <div className="logos">
            <img className="img-l" src="/assets/logo_loteria.png"></img>
            <img className="img-r" src="/assets/logo_chances.png"></img>
          </div>
        </div>
        <div id="inicio-r">
          <h1>Bienvenidos a la exposición virtual sobre la historia de la Junta de Protección Social.</h1>
          <p>En esta exposición encontrará los hitos más importantes de nuestros 175 años de historia. Este viaje lo llevará desde la construcción del hospital San Juan de Dios, pasando por el primer sorteo de lotería y la creación del Hospital Nacional de Niños hasta llegar a la actualidad.</p>
          <p>La Junta de Protección Social ha sido parte integral de la historia de Costa Rica y queremos que usted sea parte de ella.</p>
          <button onClick={() => {
            animateTitle();
          }} >Comenzar el Viaje</button>
        </div>
      </div>
    </div>
  );
};

export default Home;
