import * as firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyDDyNBJHbRh7ix1a17m_kENbZSefCSr9Sw",
    authDomain: "timeline-eb14f.firebaseapp.com",
    databaseURL: "https://timeline-eb14f.firebaseio.com",
    projectId: "timeline-eb14f",
    storageBucket: "timeline-eb14f.appspot.com",
    messagingSenderId: "121031432983",
    appId: "1:121031432983:web:5337d9019b8b5c94893552",
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export async function getData() {
    return (await (await db.collection('root').doc('main').collection('hitos').get()).docs);
}

export async function saveContribute(nombre: string, email: string, telefono: string, tipo: string, comentarios: string) {
    return await db.collection('root').doc('main').collection('contribuciones').add({nombre, email, telefono, tipo, comentarios});
}
